.img1 {
  height: inherit;
  width: 329px;
}

.img2 {
  border-radius: 6px;
  height: 45vh;
  width: 26vw;
}

.Explore {
  font-weight: 700;
  font-size: 71.1px;
}

.inviteTitle {
  background: #7fffd4;
  font-size: 33px;
  font-weight: 600;
  height: 96.5%;
  /* text-align: center;
  padding: 20px; */
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1350px) {
  .img1 {
    width: 260px;
  }
}

@media screen and (max-width: 1200px) {
  .inviteTitle {
    font-size: 27px;
    height: 94.5%;
  }
  .img1 {
    width: 215px;
  }
}

@media screen and (max-width: 1000px) {
  .img2 {
    height: 40vh;
  }
  .img1 {
    width: 190px;
  }
  .inviteTitle {
    font-size: 23px;
    height: 94.5%;
  }
}

@media screen and (max-width: 900px) {
  /* .img1 {
    width: 200px;
  } */

  .img2 {
    height: 30vh;
    width: 30vw;
  }
  .Explore {
    font-size: 6.4vw;
  }
}

@media screen and (max-width: 700px) {
  /* .img1 {
    width: 170px;
  } */

  .img2 {
    height: 20vh;
  }
  .Explore {
    font-size: 5.3vw;
  }
}

@media screen and (max-width: 590px) {
  .img1 {
    width: 150px;
  }
  .inviteTitle {
    font-size: 20px;
    height: 92.5%;
  }

  .img2 {
    height: 13vh;
  }
  .Explore {
    font-size: 4.8vw;
  }
}

@media screen and (max-width: 500px) {
  .img1 {
    width: 125px;
  }
  .inviteTitle {
    font-size: 15px;
    height: 92.5%;
  }
}

@media screen and (max-width: 415px) {
  .img1 {
    width: 110px;
  }
  .inviteTitle {
    font-size: 12px;
    height: 92.5%;
  }
}
