.campaign-img {
  height: 115.8px;
  width: 276px;
  border-radius: 6px;
  object-fit: cover;
  border: 1px solid #d6cbcb;
}
@media only screen and (max-width: 1280px) {
  .campaign-img {
    width: 240px;
  }
}
