.swiper {
  width: 100%;
  height: 100%;
}

.swiper-button-next {
  top: 65px !important;
  color: #333 !important;
  width: 40.83px !important;
  height: 20.42px !important;
}

.swiper-button-prev {
  top: 65px !important;
  color: #333 !important;
  width: 40.83px !important;
  height: 20.42px !important;
}

.swiper-wrapper {
  padding: 0 50px;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 153px !important;
  margin: 0 30px;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
